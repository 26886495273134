import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const TimeLine = styled.div`
  margin: 50px 0;
  margin-left: 50px;
  height: 2px;
  width: 100%;
  background-color: #ccc;
`;

const TimeLineProgress = styled.div`
  width: ${props => `${props.width}%`};
  height: 100%;
  background-color: #c42823;
`;

const TimeLineItems = styled.div`
  margin-left: -10px;
  margin-right: -10px;
  margin-top: -12px;
  display: flex;
  justify-content: space-between;
`;

const TimeLineItem = styled.div`
  position: relative;

  &::before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: ${props => (props.active ? "#c42823" : "#ccc")};
    display: block;
    border-radius: 100%;
  }
`;

const TimeLineContent = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 1.2rem;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 150px;
  margin-top: 15px;
`;

const Picture = styled.img`
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.75);
`;

const Label = styled.div`
  margin: 1rem 0;
  font-size: 1.2rem;
`;

const Timeline = props => {
  const { items, image } = props;
  const totalItems = items.length;
  const numberOfActiveItems = items.filter(item => item.active).length;
  const progressBarWidth =
    numberOfActiveItems > 1
      ? ((numberOfActiveItems - 1) / (totalItems - 1)) * 100
      : 0;
  return (
    <Wrapper>
      <PictureWrapper>
        <Picture src={image} />
        <Label>Diane Feinstein</Label>
      </PictureWrapper>
      <TimeLine>
        <TimeLineProgress width={progressBarWidth} />
        <TimeLineItems>
          {items.map((item, i) => (
            <TimeLineItem key={i} active={item.active}>
              <TimeLineContent>{item.name}</TimeLineContent>
            </TimeLineItem>
          ))}
        </TimeLineItems>
      </TimeLine>
    </Wrapper>
  );
};

export default Timeline;
