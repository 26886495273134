import React from "react";
import styled from "styled-components";

import Picture from "../../images/feinstein.jpg";
import TimeLine from "../../components/Timeline/Timeline";

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IssuesContent = styled.div`
  max-width: 80rem;
  margin: 5rem 0 auto;
  width: 100%;
`;

const IssuesSection = styled.div`
  margin: 2rem;
`;

const Heading = styled.h1`
  text-align: center;
  font-size: 3rem;
  margin: 2rem;
`;

const SubHeading = styled.h2`
  font-size: 2rem;
  color: #424242;
  margin: 1rem 0;
`;

const Description = styled.div`
  font-size: 1.2rem;
`;

const Issue = () => {
  const items = [
    {
      name: "Proposal Delivered",
      active: true
    },
    {
      name: "Response Received",
      active: false
    },
    {
      name: "Action Taken",
      active: false
    }
  ];
  return (
    <Wrapper>
      <IssuesContent>
        <Heading>Solving the Mask Shortage</Heading>
        <IssuesSection>
          <SubHeading>Proposal Description</SubHeading>
          <Description>
            Currently, 3.5 Billion masks needed in the USA for CURRENT COVID-19
            outbreak - less than 1% of that available in national stockpile. The
            mask shortage is a national security risk, and people are more
            likely to get sick/die without proper personal protection equipment.
            Experts say the current outbreak alone will last us only 3-6 months
            more months. We will need to replenish billions in supply for years
            – similar outbreaks will be reoccurring in the future.
            <br />
            With team of experts in mass manufacturing, automation, robotics,
            supply chain management and technology assembled, we hope to begin
            the production of masks natively here in the US in order to
            alleviate our short-term supply issue and solve our long-term supply
            issue.
          </Description>
        </IssuesSection>
        <IssuesSection>
          <SubHeading>Politician Outreach</SubHeading>

          <TimeLine items={items} image={Picture} />
        </IssuesSection>
      </IssuesContent>
    </Wrapper>
  );
};

export default Issue;
