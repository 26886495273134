import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "track-a64a6.firebaseapp.com",
  databaseURL: "https://track-a64a6.firebaseio.com",
  projectId: "track-a64a6",
  storageBucket: "track-a64a6.appspot.com",
  messagingSenderId: "278884262084",
  appId: "1:278884262084:web:292075952c18bc047aa991",
  measurementId: "G-XJN10H2EQQ"
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();

export default firebase;
