import React from "react";
import styled from "styled-components";
import heroImage from "../../images/background-image.jpg";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh);
  overflow: hidden;
`;

const Hero = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  background-image: url(${heroImage});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;

const HeroInner = styled.div`
  color: var(--color-white);
`;

const Heading = styled.h1`
  /* text-align: center; */
  font-family: "Oswald", sans-serif;
  font-size: 5rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
`;

const SubHeading = styled.h2`
  /* text-align: center; */
  font-family: "Oswald", sans-serif;
  font-weight: 200;
  font-size: 3rem;
`;

const Section = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;

  background-color: ${props => (props.background ? props.background : "#FFF")};
`;

const Description = styled.div`
  margin-top: -5rem;
  font-size: 1.5rem;

  display: flex;
  flex-direction: column;
`;

const DescriptionHeading = styled.h2`
  font-size: 4rem;
`;

const DescriptionHeadingLine = styled.div`
  width: 8rem;
  background-color: #000;
  margin: 0 auto 2rem;
  display: block;
  height: 2px;
`;

const DescriptionItem = styled.div`
  margin: 1rem 0;
`;

const Issues = styled.div`
  width: 100rem;

  align-self: flex-start;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IssuesHeading = styled.h2`
  font-size: 4rem;
  text-align: center;

  margin: 3rem;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Li = styled.li`
  display: flex;
  font-size: 2rem;
`;

const Home = () => {
  return (
    <Wrapper>
      <Hero>
        <HeroInner>
          <Heading>Track Your Request</Heading>
          <SubHeading>Let's take action</SubHeading>
        </HeroInner>
      </Hero>

      <Section background="#EEEEEE">
        <Description>
          <DescriptionHeading>Why?</DescriptionHeading>
          <DescriptionHeadingLine />
          <div>
            <DescriptionItem>
              This site was born out of frustration towards government slowness
              and inaction.
            </DescriptionItem>
            <DescriptionItem>
              We want to see how long politicians take to answer our concerns
              and address (or act) on our proposals.
            </DescriptionItem>
            <DescriptionItem>
              We want to measure how efficient and effective each politician is
              at addressing citizen concerns.
            </DescriptionItem>
            <DescriptionItem>
              We want <b>action</b>.
            </DescriptionItem>
          </div>
        </Description>
      </Section>

      <Section>
        <Issues>
          <IssuesHeading>Proposals We're Tracking:</IssuesHeading>

          <Ul>
            <Li>
              <Link to="/issues/solve-mask-shortage">
                Solving the Mask Shortage
              </Link>
            </Li>
          </Ul>
        </Issues>
      </Section>
    </Wrapper>
  );
};

export default Home;
