import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
`;

const LogoIcon = styled.div`
  height: 25px;
  width: 25px;
  color: var(--color-white);
  background-color: var(--color-logo);
  border: 0 solid #605e5e;

  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const LogoText = styled.span`
  color: var(--color-text);
  padding: 0 10px;
`;

const Logo = () => {
  return (
    <Wrapper>
      <Link to="/">
        <LogoIcon>/</LogoIcon>
        <LogoText>mylenderx</LogoText>
      </Link>
    </Wrapper>
  );
};

export default Logo;
