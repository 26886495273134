import * as actions from "../actions/actionTypes";

const initialState = {
  error: null,
  loading: false,
  verifyEmail: {
    error: null,
    loading: false
  },
  recoverPassword: {
    error: null,
    loading: false
  },
  profileEdit: {
    error: null,
    loading: false
  },
  deleteUser: {
    error: null,
    loading: false
  }
};

// helper functions
const cleanUp = state => {
  return {
    error: null,
    loading: false,
    verifyEmail: {
      error: null,
      loading: false
    },
    recoverPassword: {
      error: null,
      loading: false
    },
    profileEdit: {
      error: null,
      loading: false
    },
    deleteUser: {
      error: null,
      loading: false
    }
  };
};

const authStart = state => {
  return { ...state, loading: true };
};

const authEnd = state => {
  return { ...state, loading: false };
};

const authSuccess = state => {
  return { ...state, error: false };
};

const authFail = (state, payload) => {
  return { ...state, error: payload };
};

const verifyStart = state => {
  return { ...state, verifyEmail: { ...state.verifyEmail, loading: true } };
};

const verifySuccess = state => {
  return {
    ...state,
    verifyEmail: { ...state.verifyEmail, loading: false, error: false }
  };
};

const verifyFail = (state, payload) => {
  return {
    ...state,
    verifyEmail: { ...state.verifyEmail, loading: false, error: payload }
  };
};

const recoveryStart = state => {
  return {
    ...state,
    recoverPassword: { ...state.recoverPassword, loading: false }
  };
};

const recoverySuccess = state => {
  return {
    ...state,
    recoverPassword: {
      ...state.recoverPassword,
      loading: false,
      error: false
    }
  };
};

const recoveryFail = (state, payload) => {
  return {
    ...state,
    recoverPassword: {
      ...state.recoverPassword,
      loading: false,
      error: payload
    }
  };
};

const profileEditStart = state => {
  return {
    ...state,
    profileEdit: { ...state.profileEdit, loading: false, error: null }
  };
};

const profileEditSuccess = state => {
  return {
    ...state,
    profileEdit: {
      ...state.profileEdit,
      loading: false,
      error: false
    }
  };
};

const profileEditFail = (state, payload) => {
  return {
    ...state,
    profileEdit: {
      ...state.profileEdit,
      loading: false,
      error: payload
    }
  };
};

const deleteUserStart = state => {
  return {
    ...state,
    deleteUser: { ...state.deleteUser, loading: false, error: null }
  };
};

const deleteUserSuccess = state => {
  return {
    ...state,
    deleteUser: {
      ...state.deleteUser,
      loading: false,
      error: false
    }
  };
};

const deleteUserFail = (state, payload) => {
  return {
    ...state,
    deleteUser: {
      ...state.deleteUser,
      loading: false,
      error: payload
    }
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CLEAN_UP:
      return cleanUp(state);

    case actions.AUTH_START:
      return authStart(state);

    case actions.AUTH_END:
      return authEnd(state);

    case actions.AUTH_SUCCESS:
      return authSuccess(state);

    case actions.AUTH_FAIL:
      return authFail(state, payload);

    case actions.VERIFY_START:
      return verifyStart(state);

    case actions.VERIFY_SUCCESS:
      return verifySuccess(state);

    case actions.VERIFY_FAIL:
      return verifyFail(state, payload);

    case actions.RESET_PASSWORD_START:
      return recoveryStart(state);

    case actions.RESET_PASSWORD_SUCCESS:
      return recoverySuccess(state);

    case actions.RESET_PASSWORD_FAIL:
      return recoveryFail(state, payload);

    case actions.PROFILE_EDIT_START:
      return profileEditStart(state);

    case actions.PROFILE_EDIT_SUCCESS:
      return profileEditSuccess(state);

    case actions.PROFILE_EDIT_FAIL:
      return profileEditFail(state, payload);

    case actions.DELETE_USER_START:
      return deleteUserStart(state);

    case actions.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);

    case actions.DELETE_USER_FAIL:
      return deleteUserFail(state, payload);

    default:
      return state;
  }
};
