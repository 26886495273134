import React, { useState } from "react";
import styled from "styled-components";

import NavItems from "../NavItems/NavItems";
import Logo from "../../Logo/Logo";
import Hamburger from "./Hamburger/Hamburger";

const FixedWrapper = styled.div`
  position: fixed;
  padding: 0rem 2rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  align-items: center;
  z-index: 10;
  display: none;

  @media ${props => props.theme.mediaQueries.smallest} {
    display: flex;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => (props.opened ? "100vh" : "0vh")};
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-mainDark);
  visibility: ${props => (props.opened ? "visible" : "hidden")};
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  display: none;

  @media ${props => props.theme.mediaQueries.smallest} {
    display: flex;
  }
`;

export const SideDrawer = ({ loggedIn }) => {
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <FixedWrapper>
        <Wrapper>
          <Logo />
          <Hamburger opened={isOpened} clicked={() => setIsOpened(!isOpened)} />
        </Wrapper>
      </FixedWrapper>
      <Menu opened={isOpened}>
        <NavItems
          mobile
          clicked={() => setIsOpened(false)}
          loggedIn={loggedIn}
        />
      </Menu>
    </>
  );
};

export default SideDrawer;
